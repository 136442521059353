import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { UpdateEmployee } from '../../models/employee';
import { updateEmployee } from '../../services/employees';

import UpdateEmployeeForm from './updateEmployeeForm';

/**
 * A form used to update a new user.
 *
 * @param {object} oldEmployee - The old employee data.
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function UpdateForm({ oldEmployee, handleClose, handlePostSubmit }) {
  const handleSubmit = async (newEmployee, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      await updateEmployee(
        oldEmployee.username,
        newEmployee.firstname,
        newEmployee.lastname,
        newEmployee.username,
      );
      await handlePostSubmit();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        const { message } = err.response.data;
        setFieldError('username', message);
        setSubmitting(false);
      }
    }
  };
  return (
    <Formik
      initialValues={{
        firstname: oldEmployee.firstname || '',
        lastname: oldEmployee.lastname || '',
        username: oldEmployee.username || '',
      }}
      validationSchema={UpdateEmployee}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <UpdateEmployeeForm
          handleClose={handleClose}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          submitButtonText="Change"
        />
      )}
    </Formik>
  );
}

UpdateForm.propTypes = {
  oldEmployee: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

export default UpdateForm;
