import * as Yup from 'yup';

const RegisterLicense = Yup.object().shape({
  username: Yup.string()
    .required()
    .label('Username'),
  feature: Yup.string()
    .required()
    .label('Feature'),
  expirationDate: Yup.date()
    .label('Expiration Date'),
});

const UpdateLicense = RegisterLicense;

export {
  RegisterLicense,
  UpdateLicense,
};
