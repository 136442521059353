import { Formik, Form } from 'formik';
import {
  Button,
  DialogActions,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FormikSelect from '../common/FormikSelect';
import FormikDatePicker from '../common/FormikDatePicker';

import { RegisterLicense } from '../../models/license';
import { getCustomers } from '../../services/customers';
import { registerLicense } from '../../services/licenses';

const features = [
  'flow',
  'neat',
  'sourcerer',
];

/**
 * A form used to register a new license.
 *
 * @param {string} username - The username of the license to register.
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function RegisterForm({ username, handleClose, handlePostSubmit }) {
  const [customerList, setCustomerList] = useState([]);

  const handleSubmit = async (newLicense, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      await registerLicense(
        newLicense.username,
        newLicense.feature.toLowerCase(),
        newLicense.expirationDate,
      );
      await handlePostSubmit();
    } catch (err) {
      setFieldError('username', err.message);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (username) {
        setCustomerList([username]);
      } else {
        const customers = await getCustomers();
        const customersNames = customers.map((customer) => customer.username);
        setCustomerList(customersNames);
      }
    };
    fetchData();
  }, [username]);
  return (
    <Formik
      initialValues={{
        username: username || '',
        feature: features[0].toUpperCase(),
        expirationDate: '',
      }}
      validationSchema={RegisterLicense}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box sx={{ marginBottom: 30 }}>
            <FormikSelect
              name="username"
              label="Customer"
              items={customerList}
              required
            />
            <FormikSelect
              name="feature"
              label="Feature"
              items={features.map((feature) => feature.toUpperCase())}
              required
            />
          </Box>
          <FormikDatePicker
            name="expirationDate"
            label="Expiration Date"
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={submitForm} disabled={isSubmitting} color="primary">
              Register
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  username: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  username: '',
};

export default RegisterForm;
