import http from './httpService';

const baseUrl = '/api/customers';

/**
 * Queries '/api/customers' for all customers
 * @async
 * @function getCustomers
 * @returns {object[]} - list of all customers
 */
const getCustomers = async () => {
  const { data: customers } = await http.get(baseUrl);
  return customers;
};

/**
 * Queries '/api/customers/:username/:masterPassword' for a customer by username and master password
 * @async
 * @function getCustomer
 * @param {string} username - username of the customer
 * @param {string} masterPassword - master password
 * @returns {object} - customer object
 */
const getCustomer = async (username, masterPassword) => {
  const { data: customer } = await http.get(
    `${baseUrl}/${username}`,
    { headers: { 'x-master-password': masterPassword } },
  );
  return customer;
};

/**
 * Queries '/api/customers' for creating a new customer
 * @function registerCustomer
 * @param {string} netSuiteId NetSuite ID
 * @param {string} username Username
 * @returns {object} - customer object
 */
const registerCustomer = async (netSuiteId, username, licenses = []) => {
  const { data: customer } = await http.post(baseUrl, {
    netSuiteId, username, licenses,
  });
  return customer;
};

/**
 * Queries '/api/customers/:username' for updating a customer by username
 * @function updateCustomer
 * @param {string} oldUsername Username
 * @param {string} netSuiteId NetSuite ID
 * @param {string} username Username
*/
const updateCustomer = (
  oldUsername,
  netSuiteId,
  username,
  licenses = [],
) => http.patch(`${baseUrl}/${oldUsername}`, {
  netSuiteId,
  username,
  licenses,
});

/**
 * Calls the customer delete endpoint.
 * @function deleteCustomer
 * @param {string} username Username.
 */
const deleteCustomer = (username, type) => http.delete(`${baseUrl}/${username}/${type}`);

export {
  getCustomers,
  getCustomer,
  updateCustomer,
  deleteCustomer,
  registerCustomer,
};
