/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Profile = ({ employee }) => {
  const {
    firstname,
    lastname,
    username,
  } = employee;
  return (
    <div className="container">
      <br />
      <div className="user-profile">
        <div className="box one" />
        <div className="shadow two p-3 mb-3 bg-white rounded">
          <div className="card" style={{ width: '18rem' }}>
            <div className="card-body">
              <p className="card-text">
                <ul>
                  <li>
                    <b>Name:</b>
                    {' '}
                    {`${firstname} ${lastname}`}
                  </li>
                  <li>
                    {' '}
                    <b>Username: </b>
                    {username}
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="box one" />
      </div>
    </div>
  );
};

Profile.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default Profile;
