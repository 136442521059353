import jwtDecode from 'jwt-decode';
import http from './httpService';

const baseUrl = '/api/auth';
const tokenKey = 'vt_token';

export function logout() {
  localStorage.removeItem(tokenKey);
}

/**
 * Calls the login endpoint.
 * @param {string} username The user's username.
 * @param {string} password The user's password.
 */
export async function login(username, password) {
  const { data: jwt } = await http.post(baseUrl, { username, password });
  localStorage.setItem(tokenKey, jwt);
  http.setJWT(jwt);
}

export function getCurrentUser() {
  const jwt = localStorage.getItem(tokenKey);
  try {
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJWT() {
  return localStorage.getItem(tokenKey);
}

// Set the header for logged in users.
http.setJWT(getJWT());

export default {
  login,
  logout,
  getJWT,
  getCurrentUser,
};
