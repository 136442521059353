import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { RegisterCustomer } from '../../models/customer';
import { registerCustomer } from '../../services/customers';

import RegisterCustomerForm from './registerCustomerForm';

/**
 * A form used to register a new user.
 *
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function RegisterForm({ handleClose, handlePostSubmit }) {
  const handleSubmit = async (newCustomer, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      const customer = await registerCustomer(
        newCustomer.netSuiteId,
        newCustomer.username,
      );
      await handlePostSubmit(customer);
    } catch (err) {
      if (err.response && err.response.status === 409) {
        const { message } = err.response.data;
        if (message.includes('Username')) {
          setFieldError('username', message);
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        netSuiteId: '',
        username: '',
      }}
      validationSchema={RegisterCustomer}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <RegisterCustomerForm
          handleClose={handleClose}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          submitButtonText="Register"
        />
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
