/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from '../../services/auth';

const ProtectedRoute = ({
  component: Component, render, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const user = getCurrentUser();
      if (!user) return <Redirect to="/login" />;
      return Component ? <Component {...props} user={user} /> : render(props);
    }}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
