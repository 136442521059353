import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/**
 * A dialog that display a message with a button to close it.
 *
 * @param {string} title - The title of the dialog.
 * @param {string} message - The message of the dialog.
 * @param {boolean} open - State of the dialog.
 * @param {function} setOpen - The function to set the state of the dialog.
 * @param {function} onClose - The function to call when the dialog is closed.
 */
function MessageDialog({
  title, message, open, setOpen, onClose,
}) {
  const doOnClose = onClose || (() => setOpen(false));
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          { message }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={doOnClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MessageDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};

export default MessageDialog;
