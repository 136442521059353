import { Form, Field } from 'formik';
import {
  Button,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import TextFieldNoAutofill from '../common/TextFieldNoAutofill';

/**
 * Customer form used to update or create a new customer.
 *
 * @param {function} handleClose - called on closing
 * @param {function} submitForm - called on submission
 * @param {boolean} isSubmitting - true if the form is submitting
 */

function UpdateCustomerForm({
  handleClose, submitForm, isSubmitting, submitButtonText,
}) {
  return (
    <Form>
      <Field
        component={TextFieldNoAutofill}
        name="netSuiteId"
        label="NetSuite ID"
        required
        fullWidth
        margin="normal"
      />
      <Field
        component={TextFieldNoAutofill}
        name="username"
        label="Username"
        required
        fullWidth
        margin="normal"
      />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitForm} disabled={isSubmitting} color="primary">
          {submitButtonText}
        </Button>
      </DialogActions>
    </Form>
  );
}

UpdateCustomerForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

export default UpdateCustomerForm;
