import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { getCustomer } from '../../services/customers';

/**
 * form dialog submitting the master password to recv a decrypted password
 *
 * @returns{React.Component}
 */
function MasterPassword({
  open, setOpen, username, passwordType, setPassword, onCancel,
}) {
  const doOnCancel = onCancel || (() => setOpen(false));
  const [passwordCandidate, setPasswordCandidate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = useCallback(({ target }) => {
    setPasswordCandidate(target.value);
    if (errorMessage !== '') {
      setErrorMessage('');
    }
  }, [errorMessage]);

  const handleSubmit = useCallback(async () => {
    try {
      const customer = await getCustomer(username, passwordCandidate);
      setPassword(customer[passwordType]);
      setOpen(false);
    } catch {
      setErrorMessage('Invalid password!');
    }
  }, [passwordCandidate, username, passwordType, setPassword, setOpen]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Enter Master Password
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            onChange={handleChange}
            id="master-password"
            label="Master Password"
            type="password"
            autoComplete="current-password"
          />
          <Typography variant="subtitle2" className="text-danger">{errorMessage}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={doOnCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MasterPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  passwordType: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onCancel: PropTypes.func,
};

export default MasterPassword;
