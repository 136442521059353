import * as Yup from 'yup';

const RegisterCustomer = Yup.object().shape({
  netSuiteId: Yup.string()
    .required()
    .max(50)
    .label('NetSuite ID'),
  username: Yup.string()
    .required()
    .min(5)
    .max(50)
    .label('Username'),
});

const UpdateCustomer = RegisterCustomer;

export {
  RegisterCustomer,
  UpdateCustomer,
};
