import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { RegisterEmployee } from '../../models/employee';
import { registerEmployee } from '../../services/employees';

import RegisterEmployeeForm from './registerEmployeeForm';

/**
 * A form used to register a new user.
 *
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function RegisterForm({ handleClose, handlePostSubmit }) {
  const handleSubmit = async (newEmployee, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      await registerEmployee(
        newEmployee.firstname,
        newEmployee.lastname,
        newEmployee.username,
        newEmployee.password,
      );
      await handlePostSubmit();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        const { message } = err.response.data;
        setFieldError('username', message);
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        username: '',
        password: '',
      }}
      validationSchema={RegisterEmployee}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <RegisterEmployeeForm
          handleClose={handleClose}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          submitButtonText="Register"
        />
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

export default RegisterForm;
