import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { FileCopy, Visibility } from '@material-ui/icons';

import PropTypes from 'prop-types';
import { React, useState } from 'react';
import copy from 'clipboard-copy';

import MasterPassword from './masterPassword';

/**
 * list item for a hidden password field
 *
 * @returns {React.Component}
 */
const ListItemPassword = ({ customerUsername, passwordText, passwordType }) => {
  const textHiddenPassword = '****************';
  const [password, setPassword] = useState(textHiddenPassword);
  const [isMasterPasswordOpen, setIsMasterPasswordOpen] = useState(false);
  return (
    <ListItem>
      <ListItemText primary={passwordText} secondary={password} />
      {password === textHiddenPassword && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="copy to clipboard"
            onClick={async () => {
              setIsMasterPasswordOpen(true);
            }}
          >
            <Visibility />
          </IconButton>
        </ListItemSecondaryAction>
      )}
      {password !== textHiddenPassword && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="copy to clipboard" onClick={() => copy(password)}>
            <FileCopy />
          </IconButton>
        </ListItemSecondaryAction>
      )}

      <MasterPassword
        open={isMasterPasswordOpen}
        setOpen={setIsMasterPasswordOpen}
        username={customerUsername}
        passwordType={passwordType}
        setPassword={setPassword}
      />
    </ListItem>
  );
};

ListItemPassword.propTypes = {
  customerUsername: PropTypes.string.isRequired,
  passwordText: PropTypes.string.isRequired,
  passwordType: PropTypes.string.isRequired,
};

export default ListItemPassword;
