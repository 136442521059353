import {
  Button,
  IconButton,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { FileCopy } from '@material-ui/icons';

import PropTypes from 'prop-types';
import React from 'react';
import copy from 'clipboard-copy';

/**
 * A modal dialog used to show the details of a customer including passwords.
 *
 * @param {object} customer - The customer to show.
 * @param {function} handleClose - called on closing
 */
function CustomerDetailsPasswords({ customer, handleClose }) {
  const dateFormatString = new Date(customer.customerSince).toString().substring(0, 15);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <List>
            <ListItem>
              <ListItemText primary="NetSuite ID" secondary={customer.netSuiteId} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Username" secondary={customer.username} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Customer Since" secondary={dateFormatString} />
            </ListItem>
            <ListItem>
              <ListItemText primary="SSH Port" secondary={customer.sshPort} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={5}>
          <List>
            <ListItem>
              <ListItemText primary="Customer Password" secondary={customer.password} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy to clipboard" onClick={() => copy(customer.password)}>
                  <FileCopy />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Server Password" secondary={customer.serverPassword} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy to clipboard" onClick={() => copy(customer.serverPassword)}>
                  <FileCopy />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Admin Password" secondary={customer.adminPassword} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy to clipboard" onClick={() => copy(customer.adminPassword)}>
                  <FileCopy />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="BIOS Password" secondary={customer.biosPassword} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy to clipboard" onClick={() => copy(customer.biosPassword)}>
                  <FileCopy />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
}

CustomerDetailsPasswords.propTypes = {
  customer: PropTypes.shape({
    netSuiteId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    sshPort: PropTypes.number.isRequired,
    password: PropTypes.string.isRequired,
    serverPassword: PropTypes.string.isRequired,
    adminPassword: PropTypes.string.isRequired,
    biosPassword: PropTypes.string.isRequired,
    customerSince: PropTypes.string.isRequired,
    deletedBy: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomerDetailsPasswords;
