import { useHistory, useLocation } from 'react-router-dom';

import { logout } from '../services/auth';

const Logout = () => {
  const history = useHistory();
  const location = useLocation();

  logout();
  history.push('/login', location.state);
  return null;
};

export default Logout;
