import * as Yup from 'yup';

const RegisterEmployee = Yup.object().shape({
  firstname: Yup.string()
    .required()
    .max(50)
    .label('First Name'),
  lastname: Yup.string()
    .required()
    .max(50)
    .label('Last Name'),
  username: Yup.string()
    .required()
    .min(5)
    .max(50)
    .label('Username'),
  password: Yup.string()
    .required()
    .min(8)
    .max(1024)
    .matches(/[A-Z]/, 'Password must have at least one capital letter')
    .matches(/[a-z]/, 'Password must have at least one lowercase letter')
    .matches(/\d/, 'Password must have at least one number')
    .matches(/[^A-Za-z0-9]/, 'Password must have at least one special character')
    .label('Password'),
});

const UpdateEmployee = Yup.object().shape({
  firstname: Yup.string()
    .required()
    .max(50)
    .label('First Name'),
  lastname: Yup.string()
    .required()
    .max(50)
    .label('Last Name'),
  username: Yup.string()
    .required()
    .min(5)
    .max(50)
    .label('Username'),
});

export {
  RegisterEmployee,
  UpdateEmployee,
};
