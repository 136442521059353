import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Field, ErrorMessage } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 250,
  },
}));

/**
 * FormikSelect
 *
 * renders a generic select component
 *
 * @param {Object} props - component's properties
 * @param {string} props.name - the name of the control
 * @param {string} props.label - the label of the input
 * @param {string} props.value - the selected option
 * @param {string[]} props.options - available options
 * @param {function} props.onChange - invoked when an option is selected
 * @returns {React.Component} - returns a select component within a form
 */
const MaterialUISelectField = ({
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      >
        {children}
      </Select>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

MaterialUISelectField.propTypes = {
  errorString: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

const FormikSelect = ({
  name,
  items,
  label,
  required = true,
}) => (
  <Field
    name={name}
    as={MaterialUISelectField}
    label={label}
    errorString={<ErrorMessage name={name} />}
    required={required}
  >
    {items.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
  </Field>
);

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default FormikSelect;
