import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  VisibilityOutlined,
  EditOutlined,
} from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';

import AddButton from '../common/AddButton';
import ConfirmDialog from '../modal/ConfirmDialog';
import MessageDialog from '../modal/MessageDialog';
import { getEmployees, deleteEmployee } from '../../services/employees';
import RegisterEmployee from './registerEmployee';
import ShowEmployee from './showEmployee';
import UpdateEmployee from './updateEmployee';

const Employees = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showDialogOpen, setShowDialogOpen] = useState(false);
  const [rowToShow, setRowToShow] = useState({});

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});

  // configuration for the confirm dialog spawned when deleting employees
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleShowDialogOpen = () => setShowDialogOpen(true);
  const handleShowDialogClose = () => setShowDialogOpen(false);

  const handleEditDialogOpen = () => setEditDialogOpen(true);
  const handleEditDialogClose = () => setEditDialogOpen(false);

  const addEmployeeText = 'Register employee';
  const showEmployeeText = 'Employee details';
  const editEmployeeText = 'Change employee';

  const fetchData = useCallback(async () => {
    const newEmployees = await getEmployees();
    setEmployeeList(newEmployees);
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async () => {
    try {
      await deleteEmployee(rowToDelete.username);
      await fetchData();
      setConfirmDeleteOpen(false);
    } catch (err) {
      if (err.response && err.response.status === 409) {
        await fetchData();
        setConfirmDeleteOpen(false);
        setErrorMessage(err.response.data.message);
        setErrorDialogOpen(true);
      }
    }
  };

  const columns = [
    { title: 'Username', field: 'username' },
    { title: 'First Name', field: 'firstname' },
    { title: 'Last Name', field: 'lastname' },
  ];

  const actions = [
    {
      icon: '',
      tooltip: addEmployeeText,
      isFreeAction: true,
      onClick: handleDialogOpen,
    },
  ];

  actions.push(() => ({
    icon: VisibilityOutlined,
    tooltip: showEmployeeText,
    // Open the modal when clicked
    onClick: (event, row) => {
      setRowToShow(row);
      handleShowDialogOpen();
    },
  }));

  actions.push(() => ({
    icon: EditOutlined,
    tooltip: editEmployeeText,
    // Open the modal when clicked
    onClick: (event, row) => {
      setRowToEdit(row);
      handleEditDialogOpen();
    },
  }));

  actions.push(() => ({
    icon: 'delete_outline',
    tooltip: 'Delete a employee account',
    // Open the modal when clicked
    onClick: (event, row) => {
      const { firstname, lastname } = row;
      setDeleteMessage(`Are you sure you want to delete '${firstname} ${lastname}'?`);
      setRowToDelete(row);
      setConfirmDeleteOpen(true);
    },
  }));

  return (
    <>
      <MaterialTable
        title="Employees"
        columns={columns}
        data={employeeList}
        actions={actions}
        options={{
          paging: false,
        }}
        components={{
          Action: (componentProps) => {
            const { action } = componentProps;
            if (action.tooltip === addEmployeeText) {
              return (
                <AddButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => action.onClick(event, componentProps.data)}
                >
                  {addEmployeeText}
                </AddButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullwidth
      >
        <DialogTitle>{addEmployeeText}</DialogTitle>
        <DialogContent>
          <RegisterEmployee
            handleClose={handleDialogClose}
            handlePostSubmit={async () => {
              await fetchData();
              handleDialogClose();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Show Dialog */}
      <Dialog
        open={showDialogOpen}
        onClose={handleShowDialogClose}
        fullwidth
      >
        <DialogTitle>{showEmployeeText}</DialogTitle>
        <DialogContent>
          <ShowEmployee
            employee={rowToShow}
            handleClose={handleShowDialogClose}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        fullwidth
      >
        <DialogTitle>{editEmployeeText}</DialogTitle>
        <DialogContent>
          <UpdateEmployee
            oldEmployee={rowToEdit}
            handleClose={handleEditDialogClose}
            handlePostSubmit={async () => {
              await fetchData();
              handleEditDialogClose();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Delete Confirm Dialog */}
      <ConfirmDialog
        title="Delete Employee Account"
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDelete()}
      />

      <MessageDialog
        title="Warning - Delete Employee Account"
        message={errorMessage}
        open={errorDialogOpen}
        setOpen={setErrorDialogOpen}
      />
    </>
  );
};

export default Employees;
