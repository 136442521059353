import http from './httpService';

const baseUrl = '/api/licenses';

/**
 * Queries '/api/licenses' for all licenses
 * @async
 * @function getLicenses
 * @returns {object[]} - list of all licenses
 */
async function getLicenses() {
  const { data: licenses } = await http.get(baseUrl);
  return licenses;
}

/**
 * Queries '/api/license/:id' for a license by id
 * @async
 * @function getLicense
 * @param {string} id Id of the license
 * @returns {object} - License object
 */
async function getLicense(id) {
  const { data: license } = await http.get(`${baseUrl}/${id}`);
  return license;
}

/**
 * Queries '/api/licenses' for creating a new license
 * @function registerLicense
 * @param {string} username Customer username.
 * @param {string} feature Feature.
 * @param {date} expirationDate Expiration date.
 */
function registerLicense(username, feature, expirationDate) {
  return http.post(baseUrl, { username, feature, expirationDate });
}

/**
 * Queries '/api/licenses/:id' for updating a license by id
 * @function updateLicense
 * @param {string} id License id.
 * @param {string} username Customer username.
 * @param {string} feature Feature.
 * @param {date} expirationDate Expiration date.

*/
function updateLicense(id, username, feature, expirationDate) {
  return http.put(`${baseUrl}/${id}`, { username, feature, expirationDate });
}

/**
 * Calls the license delete endpoint.
 * @function deleteLicense
 * @param {string} id Id of the license to delete
 * @param {string} type Type of delete [soft|hard]
 */
function deleteLicense(id, type) {
  return http.delete(`${baseUrl}/${id}/${type}`);
}

export {
  getLicenses,
  getLicense,
  registerLicense,
  updateLicense,
  deleteLicense,
};
