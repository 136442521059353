import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel, Select } from 'formik-material-ui';
import { Button, DialogActions, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

import TextFieldNoAutofill from '../common/TextFieldNoAutofill';
import { NewBrowserSupportEntry } from '../../models/browserSupport';
import { putBrowserSupportEntry } from '../../services/browserSupport';
import { browserNameMap } from './utils';

/**
 * Allows users to create or update a browser support entry
 *
 * @param {object} props
 * @param {function} props.handleClose - called on closing
 * @returns {React.Component}
 */
function PutBrowserSupportEntry({ handleClose }) {
  const handleSubmit = async (newBrowserSupportEntry, { setSubmitting }) => {
    try {
      await putBrowserSupportEntry(newBrowserSupportEntry);
      handleClose();
    } catch (err) {
      let errText = 'Error creating/updating entry: ';
      if (err.response) {
        errText += JSON.stringify(err.response.data);
      } else {
        errText += JSON.stringify(err);
      }
      toast.error(errText);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        browserName: 'chrome',
        browserVersion: '',
        flowVersion: '',
        isSupported: false,
      }}
      validationSchema={NewBrowserSupportEntry}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field
            component={Select}
            name="browserName"
            label="Browser Name"
            required
            fullWidth
            margin="dense"
          >
            <MenuItem value="chrome">{browserNameMap.chrome}</MenuItem>
          </Field>
          <Field
            component={TextFieldNoAutofill}
            name="browserVersion"
            label="Browser Version"
            required
            fullWidth
            margin="normal"
          />
          <Field
            component={TextFieldNoAutofill}
            name="flowVersion"
            label="FLOW Version"
            required
            fullWidth
            margin="normal"
          />
          <Field
            component={CheckboxWithLabel}
            name="isSupported"
            type="checkbox"
            Label={{ label: 'Is Supported' }}
            required
            margin="dense"
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={submitForm} disabled={isSubmitting} color="primary">
              Register
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

PutBrowserSupportEntry.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default PutBrowserSupportEntry;
