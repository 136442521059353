import { detect } from 'detect-browser';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Switch,
  NavLink,
  Link as RouterLink,
  withRouter,
} from 'react-router-dom';
import {
  AppBar,
  Box,
  Chip,
  Link,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import SvgIcon from './icons';
import BrowserSupport from './components/browserSupport';
import Customers from './components/customer/customers';
import Licenses from './components/license/licenses';
import Employees from './components/employee/employees';
import ProtectedRoute from './components/common/protectedRoute';
import Home from './components/home';
import Login from './components/login';
import Logout from './components/logout';
import Profile from './components/profile';
import { getCurrentUser } from './services/auth';
import { version } from '../package.json';
import { colors } from './colorscheme';

const drawerWidth = 240;

// icon size of the side-bar menu in pts
const sidebarIconSize = 35;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: colors.AppBar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  main: {
    backgroundColor: colors.Background,
    flexGrow: 1,
    minHeight: '100vh',
  },
  content: {
    backgroundColor: colors.Background,
    flexGrow: 1,
    minHeight: '100vh',
    padding: theme.spacing(3),
  },
  leftNavBarItem: {
    marginRight: theme.spacing(2),
  },
  belLogoItem: {
    height: 45,
    marginInline: 'auto',
    marginBottom: '10px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
  mainIcon: {
    margin: 10,
    fontSize: 'large',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: colors.Button,
      light: '#73e8ff',
      dark: '#0086c3',
    },
  },
});

/**
 * ListItemLink
 *
 * @param {object} props - component properties
 * @param {string} props.title - list item's title
 * @param {string} props.link - list item's link
 * @param {React.Component} props.Icon - Icon to be displayed along with the list item
 * @param {number} props.badgeContent - badge content to be displayed on the list item
 * @param {function} props.onClick - invoked when item is clicked
 * @returns {React.Component} - returns a list item with a navigation link
 */
function ListItemLink({
  title,
  link,
  Icon,
  badgeContent,
  onClick,
}) {
  return (
    <NavLink
      color="inherit"
      to={link}
      activeStyle={{
        color: 'blue',
      }}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <ListItem
        button
        key={title}
        onClick={onClick}
      >
        <ListItemIcon>
          <Badge
            color="secondary"
            badgeContent={badgeContent}
          >
            <Icon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </NavLink>
  );
}

ListItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  badgeContent: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

function ButtonAppBar(props) {
  const classes = useStyles();
  const user = getCurrentUser();
  const browser = detect();

  /**
   * @typedef {Object} View
   * @property {string} title
   * @property {string} link
   * @property {React.ReactNode} Icon
   */

  /** @type {View[]} */
  const views = [
    {
      title: 'Customers',
      link: '/customers',
      Icon: SvgIcon({ name: 'Users', width: sidebarIconSize }),
    },
    {
      title: 'Licenses',
      link: '/licenses',
      Icon: SvgIcon({ name: 'Licenses', width: sidebarIconSize }),
    },
    {
      title: 'Employees',
      link: '/employees',
      Icon: SvgIcon({ name: 'Users', width: sidebarIconSize }),
    },
    {
      title: 'Browser Support',
      link: '/browserSupport',
      Icon: SvgIcon({ name: 'BrowserSupport', width: sidebarIconSize }),
    },
  ];

  const BelIcon = SvgIcon({
    name: 'BEL', className: classes.belLogoItem,
  });
  const UserIcon = SvgIcon({ name: 'UserProfile', width: 30 });

  switch (browser && browser.name) {
    case 'chrome':
      return (
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.location.pathname !== '/login'
            && (
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <Box display="flex" flexGrow={1} alignItems="center">
                  <span className={classes.mainIcon}>FLOW License Server</span>
                  <Chip
                    label={`v ${version}`}
                    className={classes.leftNavBarItem}
                    size="small"
                  />
                </Box>
                {user && (
                <>
                  <UserIcon />
                  <div>
                    <p
                      style={{ margin: '0px 10px', 'text-align': 'center' }}
                    >
                      <b>{user && `${user.firstname} ${user.lastname}`.toUpperCase()}</b>
                    </p>
                  </div>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to="/logout"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy="log-out"
                    >
                      LOG OUT
                    </Button>
                  </Link>
                </>
                )}
              </Toolbar>
            </AppBar>
            )}

            {/*
            Drawer

            This is displayed depending on whether a user is present. The content of
            each "drawer" is not actually dependent on the drawer object, but is
            determined below in the content section.
             */}

            {user && (
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.toolbar} />
                <div style={{ flexGrow: 1 }}>
                  <List>
                    {views.map(({
                      link,
                      title,
                      Icon,
                    }) => (
                      <ListItemLink
                        title={title}
                        link={link}
                        Icon={Icon}
                      />
                    ))}
                  </List>
                  <Divider />
                </div>
                <BelIcon />
              </Drawer>
            )}

            {/*
            Content

            We are using the Switch from react-router-dom to determine what to display
            in the content for each selected drawer.

            If the drawer is not open, the routes are determined from the selection on the
            AppBar.
             */}
            <main className={classes.main}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <div className={classes.content}>
                  <div className={classes.toolbar} />
                  <div className="content">
                    <ProtectedRoute path="/profile" component={Profile} />
                    <ProtectedRoute path="/customers" component={Customers} />
                    <ProtectedRoute path="/licenses" component={Licenses} />
                    <ProtectedRoute path="/employees" component={Employees} />
                    <ProtectedRoute path="/browserSupport" component={BrowserSupport} />
                    <Route path="/" exact component={Home} />
                  </div>
                </div>
              </Switch>
            </main>
          </ThemeProvider>
        </div>
      );
    case 'edge':
    case 'firefox':
      return `Unsupported browser(${browser.name}): Please use Chrome!`;
    default:
      return 'Unsupported browser(unknown): Please use Chrome!';
  }
}

export default withRouter(ButtonAppBar);
