import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  ToggleOnOutlined,
  DeleteOutlined,
} from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AddButton from '../common/AddButton';
import ConfirmDialog from '../modal/ConfirmDialog';
import { getLicenses, deleteLicense } from '../../services/licenses';
import RegisterLicense from './registerLicense';

const Licenses = () => {
  const [licenseList, setLicenseList] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [confirmToggleOpen, setConfirmToggleOpen] = useState(false);
  const [toggleMessage, setToggleMessage] = useState('');
  const [rowToToggle, setRowToToggle] = useState({});

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});

  const location = useLocation();

  const handleCreateDialogOpen = () => setCreateDialogOpen(true);
  const handleCreateDialogClose = () => setCreateDialogOpen(false);

  const addLicenseText = 'Register license';
  const toggleLicenseText = 'Toggle license status';
  const deleteLicenseText = 'Delete license';

  const formatDate = (date) => {
    const dateToFormat = new Date(date);
    return dateToFormat.toString().substring(0, 15);
  };

  const assembleStatusAndExpirationDate = ({ deletedBy, expirationDate, ...rest }) => ({
    ...rest,
    expirationDate: expirationDate ? formatDate(expirationDate) : '-',
    status: deletedBy ? 'Inactive' : 'Active',
    deletedBy,
  });

  const fetchData = useCallback(async () => {
    if (location.state) {
      if (location.state.data) {
        const { username } = location.state.data;
        const newLicenses = await getLicenses();
        const filteredLicenses = newLicenses.filter(
          (license) => license.customer.username === username,
        );
        const withStatus = filteredLicenses.map(assembleStatusAndExpirationDate);
        withStatus.sort((a, b) => a.status.localeCompare(b.status));
        setLicenseList(withStatus);
      }
    } else {
      const newLicenses = await getLicenses();
      const withStatus = newLicenses.map(assembleStatusAndExpirationDate);
      withStatus.sort((a, b) => a.status.localeCompare(b.status));
      setLicenseList(withStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleToggle = async () => {
    await deleteLicense(rowToToggle._id, 'soft');
    await fetchData();
    setConfirmToggleOpen(false);
  };

  const handleDelete = async () => {
    await deleteLicense(rowToDelete._id, 'hard');
    await fetchData();
    setConfirmDeleteOpen(false);
  };

  const columns = [
    { title: 'ID', field: '_id' },
    { title: 'Customer', field: 'customer.username' },
    { title: 'Feature', render: ({ feature }) => feature.toUpperCase() },
    { title: 'Expiration Date', field: 'expirationDate' },
    { title: 'Status', field: 'status' },
  ];

  const actions = [
    {
      icon: '',
      tooltip: addLicenseText,
      isFreeAction: true,
      onClick: handleCreateDialogOpen,
    },
  ];

  actions.push(() => ({
    icon: ToggleOnOutlined,
    tooltip: toggleLicenseText,
    // Open the modal when clicked
    onClick: (event, row) => {
      const { customer, feature } = row;
      setToggleMessage(`Are you sure you want to toggle license status '${customer.username} - ${feature}'?`);
      setRowToToggle(row);
      setConfirmToggleOpen(true);
    },
  }));

  actions.push(() => ({
    icon: DeleteOutlined,
    tooltip: deleteLicenseText,
    // Open the modal when clicked
    onClick: (event, row) => {
      const { customer, feature } = row;
      setDeleteMessage(`Are you sure you want to delete '${customer.username} - ${feature}'?`);
      setRowToDelete(row);
      setConfirmDeleteOpen(true);
    },
  }));
  let username;
  if (location.state?.data) {
    username = location.state.data.username;
  }
  return (
    <>
      <MaterialTable
        title={username ? `Licenses of ${username}` : 'Licenses'}
        columns={columns}
        data={licenseList}
        actions={actions}
        options={{
          paging: false,
        }}
        components={{
          Action: (componentProps) => {
            const { action } = componentProps;
            if (action.tooltip === addLicenseText) {
              return (
                <AddButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => action.onClick(event, componentProps.data)}
                >
                  {addLicenseText}
                </AddButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
        localization={{
          header: {
            actions: 'Actions',
          },
        }}
      />
      {/* Create Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
        fullwidth={addLicenseText}
      >
        <DialogTitle>{addLicenseText}</DialogTitle>
        <DialogContent>
          <RegisterLicense
            username={username}
            handleClose={handleCreateDialogClose}
            handlePostSubmit={async () => {
              await fetchData();
              handleCreateDialogClose();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Toggle Confirm Dialog */}
      <ConfirmDialog
        title={toggleLicenseText}
        message={toggleMessage}
        open={confirmToggleOpen}
        setOpen={setConfirmToggleOpen}
        onConfirm={() => handleToggle()}
      />

      {/* Delete Confirm Dialog */}
      <ConfirmDialog
        title={deleteLicenseText}
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};

export default Licenses;
