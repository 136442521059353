import {
  Button,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  Chip,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { React } from 'react';

import ListItemPassword from './listItemPassword';

/**
 * A form used to update a new customer.
 *
 * @param {object} oldCustomer - The old customer data.
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function CustomerDetails({ customer, handleClose }) {
  const dateFormatString = new Date(customer.customerSince).toString().substring(0, 15);
  const status = !customer.deletedBy ? 'Active' : 'Inactive';
  return (
    <>
      <Chip label={status} variant="outlined" />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <List>
            <ListItem>
              <ListItemText primary="NetSuite ID" secondary={customer.netSuiteId} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Username" secondary={customer.username} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Customer Since" secondary={dateFormatString} />
            </ListItem>
            <ListItem>
              <ListItemText primary="SSH Port" secondary={customer.sshPort} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={5}>
          <List>
            <ListItemPassword
              customerUsername={customer.username}
              passwordText="Customer Password"
              passwordType="password"
            />
            <ListItemPassword
              customerUsername={customer.username}
              passwordText="Server Password"
              passwordType="serverPassword"
            />
            <ListItemPassword
              customerUsername={customer.username}
              passwordText="Admin Password"
              passwordType="adminPassword"
            />
            <ListItemPassword
              customerUsername={customer.username}
              passwordText="BIOS Password"
              passwordType="biosPassword"
            />
          </List>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
}

CustomerDetails.propTypes = {
  customer: PropTypes.shape({
    netSuiteId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    sshPort: PropTypes.number.isRequired,
    password: PropTypes.string.isRequired,
    serverPassword: PropTypes.string.isRequired,
    adminPassword: PropTypes.string.isRequired,
    biosPassword: PropTypes.string.isRequired,
    customerSince: PropTypes.string.isRequired,
    deletedBy: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomerDetails;
