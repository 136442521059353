import { Form, Field } from 'formik';
import {
  Button,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import TextFieldNoAutofill from '../common/TextFieldNoAutofill';

/**
 * Element Styles
 * --------------
 *
 * - password: all latters are obfuscated with discs
 */
const useStyles = makeStyles({
  password: {
    '-webkit-text-security': 'disc',
  },
});

/**
 * Employee form used to create a new employee.
 *
 * @param {function} handleClose - called on closing
 * @param {function} submitForm - called on submission
 * @param {boolean} isSubmitting - true if the form is submitting
 */

function RegisterEmployeeForm({
  handleClose, submitForm, isSubmitting, submitButtonText,
}) {
  const classes = useStyles();

  return (
    <Form>
      <Field
        component={TextFieldNoAutofill}
        name="firstname"
        label="First Name"
        required
        fullWidth
        margin="normal"
      />
      <Field
        component={TextFieldNoAutofill}
        name="lastname"
        label="Last Name"
        required
        fullWidth
        margin="normal"
      />
      <Field
        component={TextFieldNoAutofill}
        name="username"
        label="Username"
        required
        fullWidth
        margin="normal"
      />
      <Field
        component={TextFieldNoAutofill}
        name="password"
        label="Password"
        required
        fullWidth
        margin="normal"
        inputProps={{ className: classes.password }}
      />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitForm} disabled={isSubmitting} color="primary">
          {submitButtonText}
        </Button>
      </DialogActions>
    </Form>
  );
}

RegisterEmployeeForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

export default RegisterEmployeeForm;
