import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FormikDatePicker = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  const configDateTimePicker = {
    name,
    ...field,
    ...props,
    type: 'date',
    variant: 'outlined',
    fullWidth: { name },
    InputLabelProps: {
      shrink: true,
    },
  };

  if (meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return (
    <TextField
      {...configDateTimePicker} // eslint-disable-line react/jsx-props-no-spreading
      // This seems like the only way to get Chrome to not use autocomplete
    />
  );
};

FormikDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikDatePicker;
