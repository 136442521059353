import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  VisibilityOutlined,
  VerifiedUserOutlined,
  EditOutlined,
  ToggleOnOutlined,
  DeleteOutlined,
} from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddButton from '../common/AddButton';
import ConfirmDialog from '../modal/ConfirmDialog';
import { getCustomers, deleteCustomer } from '../../services/customers';
import RegisterCustomer from './registerCustomer';
import ShowCustomer from './showCustomer';
import ShowCustomerPasswords from './showCustomerPasswords';
import UpdateCustomer from './updateCustomer';

const Customers = () => {
  const [customerList, setCustomerList] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [showDialogOpen, setShowDialogOpen] = useState(false);
  const [rowToShow, setRowToShow] = useState({});

  const [showCustomerPasswordsDialogOpen, setShowCustomerPasswordsDialogOpen] = useState(false);
  const [rowToShowCustomerPasswords, setRowToShowCustomerPasswords] = useState({});

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});

  const [confirmToggleOpen, setConfirmToggleOpen] = useState(false);
  const [toggleMessage, setToggleMessage] = useState('');
  const [rowToToggle, setRowToToggle] = useState({});

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});

  const history = useHistory();

  const handleCreateDialogOpen = () => setCreateDialogOpen(true);
  const handleCreateDialogClose = () => setCreateDialogOpen(false);

  const handleShowDialogOpen = () => setShowDialogOpen(true);
  const handleShowDialogClose = () => setShowDialogOpen(false);

  const handleShowCustomerPasswordsDialogOpen = () => setShowCustomerPasswordsDialogOpen(true);
  const handleShowCustomerPasswordsDialogClose = () => setShowCustomerPasswordsDialogOpen(false);

  const handleEditDialogOpen = () => setEditDialogOpen(true);
  const handleEditDialogClose = () => setEditDialogOpen(false);

  const addCustomerText = 'Register customer';
  const showCustomerText = 'Customer details';
  const licensesCustomerText = 'Customer licenses';
  const editCustomerText = 'Change customer';
  const toggleCustomerText = 'Toggle customer status';
  const deleteCustomerText = 'Delete customer';

  const assembleStatus = ({ deletedBy, ...rest }) => ({
    deletedBy,
    ...rest,
    status: deletedBy === undefined || deletedBy === null ? 'Active' : 'Inactive',
  });

  const fetchData = useCallback(async () => {
    const newCustomers = await getCustomers();
    const withStatus = newCustomers.map(assembleStatus);
    withStatus.sort((a, b) => a.status.localeCompare(b.status));
    setCustomerList(withStatus);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleToggle = async () => {
    await deleteCustomer(rowToToggle.username, 'soft');
    await fetchData();
    setConfirmToggleOpen(false);
  };

  const handleDelete = async () => {
    await deleteCustomer(rowToDelete.username, 'hard');
    await fetchData();
    setConfirmDeleteOpen(false);
  };

  const columns = [
    { title: 'NetSuite ID', field: 'netSuiteId' },
    { title: 'Username', field: 'username' },
    { title: 'SSH Port', field: 'sshPort' },
    { title: 'Status', field: 'status' },
  ];

  const actions = [
    {
      icon: '',
      tooltip: addCustomerText,
      isFreeAction: true,
      onClick: handleCreateDialogOpen,
    },
  ];

  actions.push(() => ({
    icon: VisibilityOutlined,
    tooltip: showCustomerText,
    // Open the modal when clicked
    onClick: (event, row) => {
      setRowToShow(row);
      handleShowDialogOpen();
    },
  }));

  actions.push(() => ({
    icon: VerifiedUserOutlined,
    tooltip: licensesCustomerText,
    // Open the modal when clicked
    onClick: (event, row) => history.push('/licenses', { data: row }),
  }));

  actions.push(() => ({
    icon: EditOutlined,
    tooltip: editCustomerText,
    // Open the modal when clicked
    onClick: (event, row) => {
      setRowToEdit(row);
      handleEditDialogOpen();
    },
  }));

  actions.push(() => ({
    icon: ToggleOnOutlined,
    tooltip: toggleCustomerText,
    // Open the modal when clicked
    onClick: (event, row) => {
      const { username } = row;
      setToggleMessage(`Are you sure you want to toggle customer status '${username}'?`);
      setRowToToggle(row);
      setConfirmToggleOpen(true);
    },
  }));

  actions.push(() => ({
    icon: DeleteOutlined,
    tooltip: deleteCustomerText,
    // Open the modal when clicked
    onClick: (event, row) => {
      const { username } = row;
      setDeleteMessage(`Are you sure you want to delete '${username}'?`);
      setRowToDelete(row);
      setConfirmDeleteOpen(true);
    },
  }));

  return (
    <>
      <MaterialTable
        title="Customers"
        columns={columns}
        data={customerList}
        actions={actions}
        options={{
          paging: false,
        }}
        components={{
          Action: (componentProps) => {
            const { action } = componentProps;
            if (action.tooltip === addCustomerText) {
              return (
                <AddButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => action.onClick(event, componentProps.data)}
                >
                  {addCustomerText}
                </AddButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
        localization={{
          header: {
            actions: 'Actions',
          },
        }}
      />
      {/* Create Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
        fullWidth
      >
        <DialogTitle>{addCustomerText}</DialogTitle>
        <DialogContent>
          <RegisterCustomer
            handleClose={handleCreateDialogClose}
            handlePostSubmit={(newCustomer) => {
              fetchData();
              handleCreateDialogClose();
              setRowToShowCustomerPasswords(newCustomer);
              handleShowCustomerPasswordsDialogOpen();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Show Dialog */}
      <Dialog
        open={showDialogOpen}
        onClose={handleShowDialogClose}
        fullWidth
      >
        <DialogTitle>{showCustomerText}</DialogTitle>
        <DialogContent>
          <ShowCustomer
            customer={rowToShow}
            handleClose={handleShowDialogClose}
          />
        </DialogContent>
      </Dialog>

      {/* Show Customer Passwords Dialog */}
      <Dialog
        open={showCustomerPasswordsDialogOpen}
        onClose={handleShowCustomerPasswordsDialogClose}
        fullWidth
      >
        <DialogTitle>{showCustomerText}</DialogTitle>
        <DialogContent>
          <ShowCustomerPasswords
            customer={rowToShowCustomerPasswords}
            handleClose={handleShowCustomerPasswordsDialogClose}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        fullWidth
      >
        <DialogTitle>{editCustomerText}</DialogTitle>
        <DialogContent>
          <UpdateCustomer
            oldCustomer={rowToEdit}
            handleClose={handleEditDialogClose}
            handlePostSubmit={async () => {
              await fetchData();
              handleEditDialogClose();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Toggle Confirm Dialog */}
      <ConfirmDialog
        title={toggleCustomerText}
        message={toggleMessage}
        open={confirmToggleOpen}
        setOpen={setConfirmToggleOpen}
        onConfirm={() => handleToggle()}
      />

      {/* Delete Confirm Dialog */}
      <ConfirmDialog
        title={deleteCustomerText}
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};

export default Customers;
