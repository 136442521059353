import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { browserNameMap } from './utils';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  tableCell: {
    border: '1px solid',
    background: '#afa',
  },
  headerCell: {
    fontWeight: 'bold',
  },
}));

// eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
const SquarePaper = ({ children, ...props }) => <Paper square {...props}>{children}</Paper>;

/**
 * Returns a color value based on the value of a browser support entry's "isSupported" field
 *
 * @param {bool|null} isSupported - Value of browser entry's "isSupported" field
 * @returns {string} CSS color value to use
 */
const getSupportedColor = (isSupported) => {
  switch (isSupported) {
    case true:
      return '#afa';
    case false:
      return '#faa';
    case null:
    default:
      return '#ffa';
  }
};

/**
 * Returns readable text representing the state of support for a browser support entry
 *
 * @param {bool|null} isSupported - Value of browser entry's "isSupported" field
 * @returns {string} Text to display
 */
const getSupportedText = (isSupported) => {
  switch (isSupported) {
    case true:
      return 'Supported';
    case false:
      return 'Not Supported';
    case null:
    default:
      return 'Unknown';
  }
};

/**
 * Renders a browser compatibility table where the specific browser (name and version) is on one
 * axis and the FLOW version is on the other axis. Each cell shows if the two are compatibile with
 * each other, if they are not compatible, or if compatibility is unknown.
 *
 * @param {object} props
 * @param {object[]} props.browserSupportEntries - The array of browser support entries
 * @returns {React.Component}
 */
const BrowserCompatTable = ({ browserSupportEntries }) => {
  const classes = useStyles();

  const colHeaders = []; // headers at the top of each column
  const rowHeaders = []; // headers at the left of each row
  const matrix = []; // compatibility matrix

  // TODO: sort row/col headers. Utilize stripped-down + styled MaterialTable?
  browserSupportEntries.forEach((entry) => {
    // Use FLOW versions as row headers
    const flowVersionString = `FLOW ${entry.flowVersion}`;
    let rowIndex = rowHeaders.indexOf(flowVersionString);
    if (rowIndex === -1) {
      // push new header and set index to last index
      rowIndex = rowHeaders.push(flowVersionString) - 1;

      // add new row and initialize each cell to null
      const lastRowIdx = matrix.push([]) - 1;
      for (let i = 0; i < colHeaders.length; i += 1) {
        matrix[lastRowIdx].push(null);
      }
    }

    // Use browser versions as column headers
    const browserVersionString = `${browserNameMap[entry.browserName]} ${entry.browserVersion}`;
    let colIndex = colHeaders.indexOf(browserVersionString);
    if (colIndex === -1) {
      // push new header and set index to last index
      colIndex = colHeaders.push(browserVersionString) - 1;

      // add new cell to each row and initialize to null
      for (let i = 0; i < rowHeaders.length; i += 1) {
        matrix[i].push(null);
      }
    }

    matrix[rowIndex][colIndex] = entry.isSupported;
  });

  return (
    <>
      <TableContainer component={SquarePaper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {colHeaders.map((cell) => (
                <TableCell className={classes.headerCell}><div>{cell}</div></TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {matrix.map((row, rowIndex) => (
              <TableRow>
                <TableCell variant="head" className={classes.headerCell}>
                  <div>{rowHeaders[rowIndex]}</div>
                </TableCell>
                {row.map((cell) => (
                  <TableCell
                    className={classes.tableCell}
                    style={{ background: getSupportedColor(cell) }}
                  >
                    <div>{getSupportedText(cell)}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

BrowserCompatTable.propTypes = {
  browserSupportEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BrowserCompatTable;
