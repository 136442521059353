import http from './httpService';

const baseUrl = '/api/browserSupport';

/**
 * Fetches browser support entries
 *
 * @returns {Promise<object[]>} Array of browser support entries
 */
const getBrowserSupportEntries = async () => {
  const { data: browserSupportEntries } = await http.get(baseUrl);
  return browserSupportEntries;
};

/**
 * Create or update a browser support entry
 *
 * @param {object} browserSupportEntry - The browser support entry properties
 */
const putBrowserSupportEntry = async (browserSupportEntry) => {
  await http.put(baseUrl, browserSupportEntry);
};

/**
 * Delete a browser support entry by its ID
 *
 * @param {string} id - The ID of the browser support entry
 */
const deleteBrowserSupportEntryById = async (id) => {
  await http.delete(`${baseUrl}/${id}`);
};

export {
  getBrowserSupportEntries,
  putBrowserSupportEntry,
  deleteBrowserSupportEntryById,
};
