import React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentUser } from '../services/auth';

/**
 * Component for route '/'
 *
 * Redirects to login page if not logged in, else it redirects to
 * '/customers'.
 */
function Home() {
  const user = getCurrentUser();
  const destination = user ? '/customers' : '/login';
  return (
    <Redirect to={destination} />
  );
}

export default Home;
