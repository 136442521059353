import http from './httpService';

const baseUrl = '/api/employees';

/**
 * Queries '/api/employees' for all employees
 *
 * @async
 * @function getEmployees
 * @returns {object[]} - list of all employees
 */
const getEmployees = async () => {
  const { data: employees } = await http.get(baseUrl);
  return employees;
};

/**
 * Queries '/api/employees/:username' for a employee by username
 * @async
 * @function getEmployee
 * @param {string} username - username of the employee
 * @returns {object} - employee object
 */
const getEmployee = async (username) => {
  const { data: employee } = await http.get(`${baseUrl}/${username}`);
  return employee;
};

/**
 * Calls the employee registration endpoint.
 * @param {string} firstname First name.
 * @param {string} lastname Last name.
 * @param {string} username Username.
 * @param {string} password Password.
 */
const registerEmployee = (
  firstname,
  lastname,
  username,
  password,
) => http.post(baseUrl, {
  firstname,
  lastname,
  username,
  password,
});

/**
 * Queries '/api/employees/:username' for updating a employee by username
 * @function updateEmployee
 * @param {string} oldUsername Username.
 * @param {string} firstname First name.
 * @param {string} lastname Last name.
 * @param {string} username Username.
*/
const updateEmployee = (
  oldUsername,
  firstname,
  lastname,
  username,
) => http.patch(`${baseUrl}/${oldUsername}`, {
  firstname,
  lastname,
  username,
});

const deleteEmployee = async (username) => http.delete(`${baseUrl}/${username}`);

export {
  getEmployees,
  getEmployee,
  updateEmployee,
  deleteEmployee,
  registerEmployee,
};
