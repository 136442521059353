/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import BEL from './BELLogo2019.png';
import Licenses from './Licenses icon color 0285A1.svg';
import Users from './Users icon color 0285A1.svg';
import Delete from './Delete icon color 0E9FA3.svg';
import UserProfile from './UserProfile icon white.svg';
import Info from './Info icon.svg';
import WhiteBEL from './BEL logo color white.svg';
import BrowserSupport from './BrowserSupportIcon.svg';

const lookup = {
  BEL,
  Info,
  Licenses,
  Users,
  Delete,
  UserProfile,
  WhiteBEL,
  BrowserSupport,
};

/**
 * Component factory to display SVGs
 *
 * @param {string} name - name of the icon, must be in lookup
 * @param {string} alt - alt property of <img>, defaults to ''
 * @param {object} props - other props passed to <img>
 * @returns {React.Component} - <img> component of the SVG
 */
function SvgIcon({ name, alt, ...props }) {
  const svg = lookup[name];
  return () => (
    <img
      src={svg}
      alt={alt || ''}
      {...props}
      data-cy={name}
      style={{
        filter: props.disabled ? 'invert(0.2) sepia(1) saturate(0) hue-rotate(0)' : 'none',
      }}
    />
  );
}

SvgIcon.propTypes = {
  disabled: PropTypes.bool,
};

export default SvgIcon;
