import * as Yup from 'yup';

const NewBrowserSupportEntry = Yup.object({
  browserName: Yup.string().required().label('Browser Name'),
  browserVersion: Yup.string().required().label('Browser Version'),
  flowVersion: Yup.string().required().label('FLOW Version'),
  isSupported: Yup.string().required().label('Is Supported'),
});

export {
  NewBrowserSupportEntry,
};
