/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import SvgIcon from '../icons';
import BackgroundImg from '../FLOW-login-background.jpg';
import { version } from '../../package.json';

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
  },
  logo: {
    'margin-top': '10%',
    width: '20vw',
  },
  belLogo: {
    'margin-top': '10%',
    width: '8vw',
  },
  version: {
    color: 'white',
    fontSize: 'smaller',
    padding: '10px',
    'margin-bottom': '2%',
  },
  title: {
    color: 'white',
    fontSize: 'larger',
    padding: '10px',
    'margin-bottom': '2%',
  },
  input: {
    display: 'block',
    boxSizing: 'border-box',
    padding: '10px',
    fontSize: '16px',
    margin: '10px',
    borderRadius: '6px',
  },
}));

function Login({ login, user, errors }) {
  const classes = useStyles();
  const formRef = createRef();

  if (user) {
    return (
      <Redirect to="/" />
    );
  }

  /* extract username and password from Form */
  const getUserInfo = () => {
    const [nameInput, passwdInput] = formRef.current;
    return {
      username: nameInput.value,
      password: passwdInput.value,
    };
  };

  /* apply login-callback with username and password */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = getUserInfo();
    await login(username, password);
  };

  const BelIcon = SvgIcon({ name: 'WhiteBEL', className: classes.belLogo, alt: '' });

  return (
    <div className={classes.container}>
      <span className={classes.title}>
        FLOW License Server
      </span>
      <span className={classes.version}>
        {`Version: ${version}`}
      </span>
      <form
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <input
          type="text"
          id="username"
          name="username"
          className={classes.input}
          placeholder="Username"
          required
        />
        <input
          type="password"
          id="password"
          name="password"
          className={classes.input}
          placeholder="Password"
          required
        />
        {errors && (
          <div className="alert alert-danger">
            Invalid username or password!
          </div>
        )}
        <button
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          style={{
            margin: '10px',
            width: '92%',
          }}
          data-cy="log-in"
        >
          LOG IN
        </button>
      </form>
      <BelIcon />
      <div
        style={{
          'margin-bottom': '5%',
        }}
      >
        <p
          className="mt-4 mb-3"
          style={{ color: '#ffffff' }}
        >
          &copy; 2021 Brain Electrophysiology Lab
        </p>
      </div>
    </div>
  );
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Login;
