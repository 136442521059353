import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { UpdateCustomer } from '../../models/customer';
import { updateCustomer } from '../../services/customers';

import UpdateCustomerForm from './updateCustomerForm';

/**
 * A form used to update a new customer.
 *
 * @param {object} oldCustomer - The old customer data.
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function UpdateForm({ oldCustomer, handleClose, handlePostSubmit }) {
  const handleSubmit = async (newCustomer, { setFieldError, setSubmitting }) => {
    // TODO: capture and validate the response
    try {
      await updateCustomer(
        oldCustomer.username,
        newCustomer.netSuiteId,
        newCustomer.username,
      );
      await handlePostSubmit();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        const { message } = err.response.data;
        if (message.includes('Username')) {
          setFieldError('username', message);
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        netSuiteId: oldCustomer.netSuiteId,
        username: oldCustomer.username || '',
      }}
      validationSchema={UpdateCustomer}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <UpdateCustomerForm
          handleClose={handleClose}
          submitForm={submitForm}
          isSubmitting={isSubmitting}
          submitButtonText="Change"
        />
      )}
    </Formik>
  );
}

UpdateForm.propTypes = {
  oldCustomer: PropTypes.shape({
    netSuiteId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePostSubmit: PropTypes.func.isRequired,
};

export default UpdateForm;
