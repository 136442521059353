import React from 'react';
import { TextField } from 'formik-material-ui';

const TextFieldNoAutofill = ({ ...props }) => (
  <TextField
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    // This seems like the only way to get Chrome to not use autocomplete
    autoComplete="new-password"
  />
);

export default TextFieldNoAutofill;
