import {
  Button,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A component used to view the details of a employee.
 *
 * @param {object} oldEmployee - The old employee data.
 * @param {function} handleClose - called on closing
 * @param {function} handlePostSubmit - called after submission
 */
function EmployeeDetails({ employee, handleClose }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <ListItem>
              <ListItemText primary="First Name" secondary={employee.firstname} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Last Name" secondary={employee.lastname} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Username" secondary={employee.username} />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
}

EmployeeDetails.propTypes = {
  employee: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EmployeeDetails;
