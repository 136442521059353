import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import {
  getBrowserSupportEntries,
  deleteBrowserSupportEntryById,
} from '../../services/browserSupport';
import ConfirmDialog from '../modal/ConfirmDialog';
import PutBrowserSupportEntry from './putBrowserSupportEntry';
import BrowserCompatTable from './browserCompatTable';
import AddButton from '../common/AddButton';

/**
 * Displays the main browser support view. This displays information about which versions of Chrome
 * are supported by different FLOW versions.
 *
 * @returns {React.Component}
 */
const BrowserSupport = () => {
  const [browserSupportEntries, setBrowserSupportEntries] = useState([]);
  const [putDialogOpen, setPutDialogOpen] = useState(false);

  // configuration for the confirm dialog spawned when deleting employees
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [rowToDelete, setRowToDelete] = useState({});

  const fetchData = useCallback(async () => {
    const newBrowserSupportEntries = await getBrowserSupportEntries();
    setBrowserSupportEntries(newBrowserSupportEntries);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePutDialogOpen = () => setPutDialogOpen(true);
  const handlePutDialogClose = () => {
    setPutDialogOpen(false);
    fetchData();
  };

  const handleDelete = async () => {
    await deleteBrowserSupportEntryById(rowToDelete._id);
    await fetchData();
    setConfirmDeleteOpen(false);
  };

  const addEntryText = 'Add/Update entry';

  const columns = [
    { title: 'Browser Name', field: 'browserName' },
    { title: 'Browser Version', field: 'browserVersion' },
    { title: 'FLOW Version', field: 'flowVersion' },
    { title: 'Is Supported', field: 'isSupported' },
    {
      title: 'Date Entered',
      field: 'dateEntered',
      render: (entry) => new Date(entry.dateEntered).toLocaleString(),
    },
  ];

  const actions = [
    {
      icon: '',
      tooltip: addEntryText,
      isFreeAction: true,
      onClick: handlePutDialogOpen,
    },
    {
      icon: 'delete_outline',
      tooltip: 'Delete a browser support entry',
      onClick: (event, row) => {
        setDeleteMessage('Are you sure you want to delete this entry?');
        setRowToDelete(row);
        setConfirmDeleteOpen(true);
      },
    },
  ];

  return (
    <>
      <MaterialTable
        title="Browser Support"
        columns={columns}
        actions={actions}
        data={browserSupportEntries}
        components={{
          Action: (componentProps) => {
            const { action } = componentProps;
            if (action.tooltip === addEntryText) {
              return (
                <AddButton
                  color="primary"
                  variant="contained"
                  onClick={(event) => action.onClick(event, componentProps.data)}
                >
                  {addEntryText}
                </AddButton>
              );
            }
            // Use the regular action component for inline actions
            return (
              <MTableAction
                {...componentProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            );
          },
        }}
      />
      {browserSupportEntries.length > 0 && (
        <BrowserCompatTable browserSupportEntries={browserSupportEntries} />
      )}
      <Dialog
        open={putDialogOpen}
        onClose={handlePutDialogClose}
        fullWidth
      >
        <DialogTitle>{addEntryText}</DialogTitle>
        <DialogContent>
          <PutBrowserSupportEntry
            handleClose={handlePutDialogClose}
          />
        </DialogContent>
      </Dialog>

      {/* Delete Confirm Dialog */}
      <ConfirmDialog
        title="Delete Browser Support Entry"
        message={deleteMessage}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};

export default BrowserSupport;
